import React from "react"
import { window } from "browser-monads";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';
import { Facebook, Twitter, Linkedin, Printer, Mail } from 'react-feather'


const SocialShareButtons = ({ url, title, description }) => (
  <div css={{
    display: `flex`,
    alignItems: `center`,
    marginTop: `16px`,
    'button': {
      display: `flex`,
      alignItems: `center`,
      marginRight: `0.79rem`,
      transition: `all 0.2s ease-out`,
      '&:hover': {
        transform: `scale(1.15)`,
      },
      '&:last-child': {
        marginRight: `0`,
      },
    },
    'svg': {
      color: `#5f6c80`,
      height: `1.802rem`,
      width: `1.802rem`
    },
    


  }}>
    <FacebookShareButton url={url} quote={description}>
      <Facebook strokeWidth={1.25} />
    </FacebookShareButton>

    <TwitterShareButton url={url} title={description}>
      <Twitter strokeWidth={1.25} />
    </TwitterShareButton>

    <LinkedinShareButton url={url} title={title} summary={description}>
      <Linkedin strokeWidth={1.25} />
    </LinkedinShareButton>

    <button css={{backgroundColor: `transparent`, border: `none`, padding: `0px`, font: `inherit`, color: `inherit`, cursor: `pointer`}} onClick={window.print}><Printer strokeWidth={1.25} /></button>

    <EmailShareButton url={url} title={description}>
      <Mail strokeWidth={1.25} />
    </EmailShareButton>    
  </div>
  )

  export default SocialShareButtons