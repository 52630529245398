import React from "react"

import Layout from "../../../layouts"
import BudgetCalculator from "../../../components/calculators/budgetCalculator"
import { Canon, Body } from "../../../components/elements/typography"
import SocialShareButtons from "../../../components/elements/socialShareButtons"
import { window } from "browser-monads"

const BudgetCalculatorPage = () => {

  return (
    <Layout isSubMenuOpen={true} openAccountLink={true}>
      <div css={{
        maxWidth: `1140px`,
        margin: `0 auto`,
        position: `relative`,
        padding: `5rem 0`,
      }}>
        <div css={{marginBottom: `3rem`, padding: `0 2rem`}}>
          <div css={{marginBottom: `1rem`}}>
            <SocialShareButtons url="https://www.tcbga.bank/resources/calculators/budget-calculator" title="Budget Calculator" description="Get started creating your budget with this simple budget calculator." />
          </div>
          <Canon>Get Your Budget Started</Canon>
          <Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vel nulla vel nulla blandit molestie et accumsan ex. Duis luctus vitae tellus a fringilla. Donec tempus fringilla tincidunt. In hac habitasse platea dictumst. Pellentesque eget nisl ac nisl dignissim volutpat vel in magna. Fusce sit amet hendrerit mi. Integer quam lacus, bibendum in venenatis quis, placerat a arcu. Ut et consectetur mauris, luctus sollicitudin lectus.<br /><br />

Interdum et malesuada fames ac ante ipsum primis in faucibus. Vivamus ut arcu lacinia, porttitor sem at, ullamcorper neque. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vivamus in tristique massa. Suspendisse sit amet sem vel quam lacinia viverra ac eget erat. Integer tristique risus ac diam tristique euismod. Sed consequat nisl id metus auctor, at bibendum erat semper. In vel pulvinar lacus, faucibus lobortis risus. Proin vestibulum nec diam et euismod. Suspendisse et eleifend tellus, at lacinia risus. Donec quam lorem, tempus a libero et, porta accumsan sem. Suspendisse iaculis elit at sapien tincidunt pretium. Etiam varius purus sit amet efficitur egestas. Vivamus ac placerat elit.
          </Body>
        </div>
        <BudgetCalculator />
        <div css={{marginTop: `3rem`, padding: `0 2rem`}}>
        <Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vel nulla vel nulla blandit molestie et accumsan ex. Duis luctus vitae tellus a fringilla. Donec tempus fringilla tincidunt. In hac habitasse platea dictumst. Pellentesque eget nisl ac nisl dignissim volutpat vel in magna. Fusce sit amet hendrerit mi. Integer quam lacus, bibendum in venenatis quis, placerat a arcu. Ut et consectetur mauris, luctus sollicitudin lectus.<br /><br />

Interdum et malesuada fames ac ante ipsum primis in faucibus. Vivamus ut arcu lacinia, porttitor sem at, ullamcorper neque. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vivamus in tristique massa. Suspendisse sit amet sem vel quam lacinia viverra ac eget erat. Integer tristique risus ac diam tristique euismod. Sed consequat nisl id metus auctor, at bibendum erat semper. In vel pulvinar lacus, faucibus lobortis risus. Proin vestibulum nec diam et euismod. Suspendisse et eleifend tellus, at lacinia risus. Donec quam lorem, tempus a libero et, porta accumsan sem. Suspendisse iaculis elit at sapien tincidunt pretium. Etiam varius purus sit amet efficitur egestas. Vivamus ac placerat elit.
          </Body>
        </div>
      </div>
    </Layout>
  )
}

export default BudgetCalculatorPage