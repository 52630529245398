import React, { useState,  useEffect } from "react"
import 'chart.js/auto';
import { Chart, Doughnut  } from 'react-chartjs-2';
import { window } from "browser-monads";

const Field = ({ color, name, text, value, changeHanler }) => {
  return (
    <div>
      <div css={{display: `inline-block`, backgroundColor: color, height: `0.75rem`, width: `0.75rem`, borderRadius: `50%`, marginRight: `0.5rem`}}></div>
      <label for={name}>{text}:</label>
      $<input id={name} type="number" value={value} onChange={e => changeHanler(e.target.value)}/>
    </div>
  )
}


const BudgetCalculator = () => {

  

  const [income, setIncome] = useState(3000)

  // Monthly Expenses
  const [rent, setRent] = useState(800)
  const [utilities, setUtilities] = useState(250)
  const [food, setFood] = useState(300)
  const [entertainment, setEntertainment] = useState(100)
  const [carPayment, setCarPayment] = useState(400)
  const [carInsurance, setCarInsurance] = useState(100)
  const [gas, setGas] = useState(400)
  const [phone, setPhone] = useState(150)
  const [internet, setInternet] = useState(60)
  const [otherMonthly, setOtherMothly] = useState(40)

  // Yearly Expenses
  const [lifeInsurance, setLifeInsurance] = useState(1200)
  const [propertyTaxes, setPropertyTaxes] = useState(600)
  const [otherYearly, setOtherYearly] = useState(1000)
  const [medical, setMedical] = useState(1200)

  // Calculated
  const [mothlyExpenses, setMonthlyExpenses] = useState(100)
  const [monthlySavings, setMonthlySavings] = useState(100)
  const [yearlySavings, setYearlySavings] = useState(100)

  const calculateSavings = () => {
    let expenses = ~~rent + ~~utilities + ~~food + ~~entertainment + ~~carPayment + ~~carInsurance + ~~gas + ~~phone + ~~internet + ~~otherMonthly + ((~~lifeInsurance + ~~propertyTaxes + ~~otherYearly + ~~medical )/12)
    let mSavings = income - expenses
    let ySavings = mSavings * 12
    setMonthlyExpenses(expenses)
    setMonthlySavings(mSavings)
    setYearlySavings(ySavings)
  }


  useEffect(() => {
    calculateSavings()
  });
  
  const data =  {
    labels: [
      `Monthly Savings`,
      `Rent / Mortgage Expense`,
      `Utilities Expense`,
      `Food Expense`,
      `Entertainment`,
      `Car Payment`,
      `Car Insurance`,
      `Gas Expense`,
      `Phone Expense`,
      `Internet Expense`,
      `Other Monthly Expenses`,
      `Life Insurance`,
      `Medical Insurance / Expenses`,
      `Property Taxes`,
      `Other Yearly Expenses`
    ],
    datasets: [
        {
            data: [
              Math.max(0, monthlySavings), 
              rent, 
              utilities, 
              food, 
              entertainment,
              carPayment,
              carInsurance,
              gas,
              phone,
              internet,
              otherMonthly,
              lifeInsurance/12,
              medical/12, 
              propertyTaxes/12,
              otherYearly/12,
            ],
            backgroundColor: [
              '#bdc3c7', //savings
              '#c0392b', //rent
              '#e74c3c', //utilities
              '#e67e22', //food
              '#f39c12', //entertainment
              '#f1c40f', //carPayment
              '#27ae60', //carInsurance
              '#1abc9c', //gas
              '#2980b9', //phone
              '#3498db', //internet
              '#8e44ad', // otherMonthly
              '#81ecec', // lifeInsurance
              '#74b9ff', //medical
              '#ffeaa7', //propertyTaxes
              '#fd79a8', //otherYearly
            ]
        },
    ],
};

  return (
    <div css={{
      backgroundColor: `#fff`,
      borderRadius: `1rem`,
      padding: `1rem 2rem`
    }}>
      <div css={{
        textAlign: `center`
      }}>
        <h1>Budget Calculator</h1>
        <p>Enter you monthly income and expensies to track your net savings.</p>
      </div>

      <div css={{
        display: `grid`,
        gridTemplateColumns: `1fr 1fr`,
        alignItems: `center`,
      }}>
        <div>
          <form>
            <div>
              <h2>Monthly After-Tax Income:</h2>
              $<input id="income" type="number" value={income} onChange={e => setIncome(~~e.target.value)}/>
            </div>
            <div>
              <h2>Monthly Expenses</h2>
              <Field color="#c0392b" name="rent" text="Rent / Mortgage" value={rent} changeHanler={setRent} />
              <Field color="#e74c3c" name="utilities" text="Utilities" value={utilities} changeHanler={setUtilities} />
              <Field color="#e67e22" name="food" text="Food" value={food} changeHanler={setFood} />
              <Field color="#f39c12" name="entertainment" text="Entertainment" value={entertainment} changeHanler={setEntertainment} />
              <Field color="#f1c40f" name="carPayment" text="Car Payment" value={carPayment} changeHanler={setCarPayment} />
              <Field color="#27ae60" name="carInsurance" text="Car Insurance" value={carInsurance} changeHanler={setCarInsurance} />
              <Field color="#1abc9c" name="gas" text="Gas" value={gas} changeHanler={setGas} />
              <Field color="#2980b9" name="phone" text="Phone" value={phone} changeHanler={setPhone} />
              <Field color="#3498db" name="internet" text="Internet" value={internet} changeHanler={setInternet} />
              <Field color="#8e44ad" name="otherMonthly" text="Other Monthly" value={otherMonthly} changeHanler={setOtherMothly} />
            </div>
            <div>
              <h2>Yearly Expenses</h2>
              <Field color="#81ecec" name="lifeInsurance" text="Life Insurance" value={lifeInsurance} changeHanler={setLifeInsurance} />
              <Field color="#74b9ff" name="medicalBills" text="Medical Expenses" value={medical} changeHanler={setMedical} />
              <Field color="#ffeaa7" name="propertyTaxes" text="Property Taxes" value={propertyTaxes} changeHanler={setPropertyTaxes} />
              <Field color="#fd79a8" name="otherYearly" text="Other Yearly" value={otherYearly} changeHanler={setOtherYearly} />
            </div>
          </form>
        </div>
        <div>
          <div css={{height: `300px`}}>
            <Doughnut options={{responsive: true, maintainAspectRatio: false, plugins: {legend: {display: false},}}} data={data} type=""/>
          </div>
          <div css={{
            display: `grid`,
            gridTemplateColumns: `1fr 1fr`,
            'div': {textAlign: `center`}
          }}>
            <div>Monthly Expenses: ${Math.round((mothlyExpenses + Number.EPSILON) * 100) / 100}</div>
            <div>
              {
                monthlySavings > 0 
                ? <span css={{color: `green`}}>Monthly Savings: ${Math.round((monthlySavings + Number.EPSILON) * 100) / 100}</span>
                : <span css={{color: `red`}}>Monthly Overages: ${Math.round((monthlySavings + Number.EPSILON) * 100) / 100}</span>
              }
              
              
            </div>  
          </div>
        </div>
      </div>
    </div>
  )
}

export default BudgetCalculator